"use client"
import React, { PropsWithChildren, forwardRef } from "react"
import { Button, ButtonProps } from "@nextui-org/react"
import { CommonComponentProps } from "@/shared/lib/component-types"

export type EmsIconButtonProps = CommonComponentProps & {
  disabled?: boolean
  onPress?: ButtonProps["onPress"]
  "aria-label"?: React.ComponentProps<"button">["aria-label"]
  className?: string
}

export const EmsIconButton = forwardRef<HTMLButtonElement, PropsWithChildren<EmsIconButtonProps>>(
  function EmsIconButton(
    { id, children, disabled, onPress, "aria-label": ariaLabel, "data-testid": dataTestid, className, ...props },
    ref,
  ) {
    return (
      <Button
        ref={ref}
        id={id}
        data-testid={dataTestid}
        className={`
        ${className ? className : ""}
        bg-transparent
        focus:bg-ems-surface-focussed
        hover:bg-ems-surface-hovered
        active:bg-ems-surface-pressed
        focus:active:bg-ems-surface-focussed-pressed
        data-[hover=true]:!opacity-100 
        data-[disabled=true]:opacity-100 
        data-[disabled=true]:text-ems-destructive-disabled 
        aria-expanded:bg-ems-surface-focussed-pressed 
        aria-expanded:text-ems-on-surface 
        aria-expanded:!opacity-100`}
        isIconOnly
        disableRipple
        radius="none"
        aria-label={ariaLabel}
        isDisabled={disabled}
        onPress={onPress}
        {...props}
      >
        {children}
      </Button>
    )
  },
)
