"use client"
import { EmsPageHeader } from "@/shared/components/ems-page-header/ems-page-header"
import { EmsIconButton } from "@/shared/components/ems-icon-button/ems-icon-button"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import SettingsIcon from "@mui/icons-material/Settings"
import HelpIcon from "@mui/icons-material/Help"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import LogoutIcon from "@mui/icons-material/Logout"
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react"
import { useTranslations } from "next-intl"
import { useSelector } from "@/lib/redux"
import { selectIsGlobalError } from "@/lib/redux/slices"
import { getEnv } from "@/lib/get-env"
import { useAuth } from "react-oidc-context"

const USER_MANUAL_KEY = "user-manual"
const ACCOUNT_SETTINGS_KEY = "account-settings"

const userManualSource: string = getEnv("EMS_FRONTEND_USER_MANUAL_SOURCE")
const accountSettingsSource: string = getEnv("EMS_FRONTEND_ACCOUNT_SETTINGS_SOURCE")
const disabledKeys: string[] = []

if (!userManualSource) {
  disabledKeys.push(USER_MANUAL_KEY)
}

if (!accountSettingsSource) {
  disabledKeys.push(ACCOUNT_SETTINGS_KEY)
}

export function Header() {
  const t = useTranslations("EmsPageHeader")
  const isGlobalError: boolean = useSelector(selectIsGlobalError)

  const { signoutRedirect } = useAuth()

  const triggerLogout = async () => {
    await signoutRedirect()
  }

  return (
    <EmsPageHeader appName={t("app")}>
      <Dropdown
        offset={4}
        classNames={{
          base: ["bg-ems-surface", "border", "border-ems-line-medium", "p-0"],
          content: ["rounded-none", "p-0", "shadow-none"],
        }}
        placement="bottom-end"
      >
        <DropdownTrigger>
          <EmsIconButton disabled={isGlobalError}>
            <HelpIcon />
          </EmsIconButton>
        </DropdownTrigger>
        <DropdownMenu
          aria-label={t("infoMenuAria")}
          disabledKeys={disabledKeys}
          classNames={{
            base: ["px-0", "py-4", "w-60"],
            list: ["gap-1"],
          }}
          itemClasses={{
            base: [
              "rounded-none",
              "text-ems-on-surface",
              "data-[hover=true]:bg-ems-surface-hovered",
              "data-[pressed=true]:bg-ems-surface-pressed",
              "pl-10",
              "h-10",
              "flex",
              "gap-1",
            ],
          }}
        >
          <DropdownItem key={USER_MANUAL_KEY} target="_blank" aria-label={t("userManualAria")} href={userManualSource}>
            {t("userManual")} <OpenInNewIcon className="m-2" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/*<EmsIconButton disabled={isGlobalError}>*/}
      {/*  <SettingsIcon />*/}
      {/*</EmsIconButton>*/}
      <Dropdown
        isDisabled={isGlobalError}
        offset={4}
        classNames={{
          base: ["bg-ems-surface", "border", "border-ems-line-medium", "p-0"],
          content: ["rounded-none", "p-0", "shadow-none"],
        }}
        placement="bottom-end"
      >
        <DropdownTrigger>
          <EmsIconButton disabled={isGlobalError}>
            <AccountCircleIcon />
          </EmsIconButton>
        </DropdownTrigger>
        <DropdownMenu
          aria-label={t("settingsMenuAria")}
          disabledKeys={disabledKeys}
          classNames={{
            base: ["px-0", "py-4", "w-60"],
            list: ["gap-1"],
          }}
          itemClasses={{
            base: [
              "rounded-none",
              "text-ems-on-surface",
              "data-[hover=true]:bg-ems-surface-hovered",
              "data-[pressed=true]:bg-ems-surface-pressed",
              "pl-10",
              "h-10",
              "flex",
              "gap-1",
            ],
          }}
        >
          <DropdownItem
            key={ACCOUNT_SETTINGS_KEY}
            target="_blank"
            aria-label={t("accountSettingsAria")}
            href={accountSettingsSource}
          >
            {t("accountSettings")}
          </DropdownItem>
          <DropdownItem key="logout" aria-label={t("logoutAria")} onClick={triggerLogout}>
            {t("logout")} <LogoutIcon className="m-2" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </EmsPageHeader>
  )
}
