"use client"
import React, { PropsWithChildren } from "react"
import { Navbar, NavbarBrand, NavbarContent } from "@nextui-org/react"
import Image from "next/image"
import { CommonComponentProps } from "@/shared/lib/component-types"

export type EmsPageHeaderProps = CommonComponentProps & {
  appName: string
}

export function EmsPageHeader({
  id,
  children,
  "data-testid": dataTestid,
  appName,
}: PropsWithChildren<EmsPageHeaderProps>) {
  return (
    <Navbar
      id={id}
      data-testid={dataTestid}
      isBordered={true}
      height="3.5rem"
      maxWidth="full"
      classNames={{
        wrapper: ["pr-4", "pl-6", "py-2"],
        base: ["border-ems-line-fair"],
        brand: ["text-ems-on-surface"],
      }}
    >
      <NavbarBrand className="py-1 flex gap-4">
        <Image height={24} src={require("../../assets/secunet_logo.png")} alt="Secunet Logo"></Image>
        <p className="text-page-header">{appName}</p>
      </NavbarBrand>
      <NavbarContent justify="end" className="gap-0">
        {children}
      </NavbarContent>
    </Navbar>
  )
}
