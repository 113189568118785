"use client"
import { PropsWithChildren, useEffect } from "react"
import { useAuth } from "react-oidc-context"
import { log } from "../logging/logger"

export function Authenticated({ children }: PropsWithChildren) {
  const { isAuthenticated, signinRedirect } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      // delay the re-login to prevent hick-ups from not-yet closed sessions
      // Without this, we noticed a behavior were the user was directly re-logged in without having to enter the credentials
      setTimeout(() => {
        log("Will trigger signinRedirect")
        signinRedirect()
      }, 100)
    }
  }, [isAuthenticated, signinRedirect])

  if (isAuthenticated) {
    return <>{children}</>
  } else {
    return null
  }
}
